import React, { ReactElement, useEffect, useState } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { Button, Icon, Modal } from '@a_team/ui-components';
import {
  Company,
  CompanyStatus,
  EnrichableCompany,
} from '@a_team/models/dist/Company';
import { CompanyV2Picker } from '.';
import { NewCompanyId } from '../CompanyPicker';
import { BorderColors, Colors } from '@ateams/components';
import CompanyAvatar from '../CompanyAvatar/CompanyAvatar';
import TextButton from '../TextButton';
import { isValidUrl } from '@src/helpers/urls';
import { useMutationCreateCompany } from '@src/rq/companiesV2Search';
import defaultCompanyLogo from './defaultCompanyLogo.svg';

const useStyles = createUseStyles({
  modal: {
    '& > div': {
      padding: 0,
    },
  },
  container: {
    padding: '40px 40px 30px',
  },
  header: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 24,
    marginTop: 0,
  },
  inputContainer: {
    marginTop: 8,
    '& span[class^="companyAvatarsContainer"]': {
      display: 'none',
    },
  },
  inputContainerName: {
    marginBottom: 24,
  },
  label: {
    fontSize: 15,
    fontWeight: 500,
    marginBottom: 12,
  },
  input: {
    zIndex: 10,
  },
  suggestionSpacing: {
    position: 'relative',
    minHeight: 48,
    padding: 8,
    paddingTop: 18,
    top: -10,
  },
  suggestion: {
    borderRadius: 8,
    background: Colors.backgroundLight,
    display: 'flex',
    justifyContent: 'space-between',
  },
  nameSuggestion: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: 8,
  },
  textButton: {
    fontWeight: 500,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${BorderColors.lighter}`,
    padding: 16,
  },
  button: {
    padding: '8px 12px !important',
    width: '80px !important',
    fontSize: 14,
  },
  errorMessage: {
    fontSize: '12px',
    color: 'red',
  },
});

const unverifiedCompany: Pick<
  Company,
  'id' | 'logoUrl' | 'status' | 'talentIndustryIds'
> = {
  id: NewCompanyId,
  logoUrl: undefined,
  status: CompanyStatus.Unverified,
  talentIndustryIds: [],
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  companyName: string;
  onSelectNewCompany: (company: Company & { url?: string }) => void;
}

const enrichableCompanyToCompanyWithName = (
  name: string,
  data: EnrichableCompany,
): Company & { url: string } => {
  return {
    name: name,
    id: data.cid,
    url: data.url,
    status: data.verified ? CompanyStatus.Verified : CompanyStatus.Unverified,
    logoUrl: data.logoUrl,
    talentIndustryIds: data.industries ?? [],
  };
};

export const AddCompanyModal = ({
  isOpen,
  onClose,
  onSelectNewCompany,
  companyName: initialCompanyName,
}: Props): ReactElement => {
  const styles = useStyles();
  const [requestUrl, setRequestUrl] = useState(false);
  const [blurredName, setBlurredName] = useState(false);
  const [suggestedName, setSuggestedName] = useState('');
  const [errors, setErrors] = useState({
    name: '',
    url: '',
  });

  // ensure refresh when setting search term from parent component
  const [nameInputKey, setNameInputKey] = useState(Date.now());
  const [urlInputKey, setUrlInputKey] = useState(Date.now());

  const [companyNameSearchTerm, setCompanyNameSearchTerm] = useState('');
  const [companyUrlSearchTerm, setCompanyUrlSearchTerm] = useState('');
  const [selectedCompany, setSelectedCompany] = useState<
    Company & { url?: string }
  >({ ...unverifiedCompany, name: initialCompanyName });

  const { mutate: createCompany, isLoading } = useMutationCreateCompany({
    onSuccess: (data) => {
      const company = enrichableCompanyToCompanyWithName(
        selectedCompany.name,
        data,
      );
      onSelectNewCompany(company);
    },
    onError: () => onSelectNewCompany(selectedCompany),
  });

  const validateForm = (): boolean => {
    const newErrors = {
      name: '',
      url: '',
    };
    let isValid = true;

    // Validate name
    if (!selectedCompany.name?.trim()) {
      newErrors.name = 'Company name is required';
      isValid = false;
    }

    // Validate URL
    if (!selectedCompany.url?.trim()) {
      newErrors.url = 'Company link is required';
      isValid = false;
    }

    // Validate URL if provided
    if (selectedCompany.url) {
      if (!isValidUrl(selectedCompany.url)) {
        newErrors.url = 'Please provide a valid link';
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const updateCompanyName = (name: string) => {
    setSelectedCompany({ ...selectedCompany, name });
    setSuggestedName('');
    if (errors.name) {
      setErrors((prev) => ({ ...prev, name: '' }));
    }
  };

  const updateCompanyUrl = (url: string | undefined) => {
    setSelectedCompany({ ...selectedCompany, ...unverifiedCompany, url });
    setSuggestedName('');
    setRequestUrl(!url);
    if (errors.url) {
      setErrors((prev) => ({ ...prev, url: '' }));
    }
  };

  const setCompanyFromName = ([company]: Array<Company & { url?: string }>) => {
    if (!company) return;

    setSelectedCompany(company);
    setSuggestedName('');
    setCompanyNameSearchTerm(company?.name || '');

    if (company?.url) {
      setErrors((prev) => ({ ...prev, url: '' }));
      setCompanyUrlSearchTerm(company?.url);
      setUrlInputKey(Date.now());
    }
  };

  const setCompanyFromUrlAndSuggestName = ([company]: Array<
    Company & { url?: string }
  >) => {
    if (!company) return;

    setSelectedCompany({ ...company, name: selectedCompany.name || '' });
    setCompanyUrlSearchTerm(company.url || '');
    setErrors((prev) => ({ ...prev, url: '' }));

    if (selectedCompany.name !== company.name) {
      setSuggestedName(company.name || '');
      setErrors((prev) => ({ ...prev, name: '' }));
    }
  };

  const acceptNameSuggestion = (name: string) => {
    setNameInputKey(Date.now());
    setCompanyFromName([{ ...selectedCompany, name: suggestedName }]);
  };

  useEffect(() => {
    updateCompanyName(initialCompanyName);
    setCompanyNameSearchTerm(initialCompanyName);
    setCompanyUrlSearchTerm('');
    setSuggestedName('');

    setNameInputKey(Date.now());
    setUrlInputKey(Date.now());
  }, [initialCompanyName]);

  useEffect(() => {
    if (blurredName) {
      setBlurredName(false);
      setRequestUrl(!!selectedCompany.name && !selectedCompany.url);
    }
  }, [blurredName]);

  const onSave = () => {
    if (validateForm()) {
      createCompany(selectedCompany as Company & { url: string });
    }
  };

  const customIcon = (
    <img
      src={selectedCompany.logoUrl || defaultCompanyLogo}
      alt="Company logo"
      width={20}
      height={20}
      style={{
        maxWidth: 20,
        maxHeight: 20,
        top: 12,
        zIndex: 99,
        left: 8,
        borderRadius: 5,
      }}
    />
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      canClose={false}
      shouldHideGradientStroke
      innerContentStyle={{ overflow: 'visible' }}
      className={styles.modal}
      style={{
        width: 680,
      }}
    >
      <div className={styles.container}>
        <h2 className={styles.header}>Add a new company</h2>
        <div className={cx(styles.inputContainer, styles.inputContainerName)}>
          <label className={styles.label}>Company name</label>
          <CompanyV2Picker
            key={`${companyNameSearchTerm}-${nameInputKey}`}
            onSelectCompanies={setCompanyFromName}
            className={styles.input}
            placeholder={'Enter name'}
            onlySearchVerified
            suppressIcons
            autoFocus
            required
            onChangeProps={{
              initialSearchTerm: companyNameSearchTerm,
              onInputChange: updateCompanyName,
              onBlur: () => setBlurredName(true),
            }}
            customIcon={customIcon}
          />
          {errors.name ? (
            <div className={styles.errorMessage}>{errors.name}</div>
          ) : (
            suggestedName && (
              <div className={cx(styles.suggestion, styles.suggestionSpacing)}>
                <div className={styles.nameSuggestion}>
                  <CompanyAvatar
                    src={selectedCompany.logoUrl}
                    size={24}
                    className={styles.avatar}
                  />
                  <span>{suggestedName}</span>
                </div>
                <TextButton
                  className={styles.textButton}
                  onClick={() => acceptNameSuggestion(suggestedName)}
                  color="secondaryDark"
                >
                  Use existing company
                </TextButton>
              </div>
            )
          )}
        </div>

        <div className={styles.inputContainer}>
          <label className={styles.label}>Company link</label>
          <CompanyV2Picker
            key={`${companyUrlSearchTerm}-${urlInputKey}}`}
            className={styles.input}
            onSelectCompanies={setCompanyFromUrlAndSuggestName}
            placeholder={'Enter link'}
            onlySearchVerified
            suppressIcons
            searchUrl
            onChangeProps={{
              initialSearchTerm: companyUrlSearchTerm,
              onInputChange: updateCompanyUrl,
            }}
          />

          {errors.url ? (
            <div className={styles.errorMessage}>{errors.url}</div>
          ) : (
            requestUrl && (
              <div className={cx(styles.suggestion, styles.suggestionSpacing)}>
                <span style={{ fontSize: '12px' }}>
                  Add a link to be matched with this or similar companies on
                  A.Team.
                </span>
              </div>
            )
          )}
        </div>
      </div>

      <div className={styles.buttonsContainer}>
        <Button
          onClick={() => onClose()}
          variant="secondary"
          className={styles.button}
        >
          Back
        </Button>
        <Button
          variant="main"
          disabled={isLoading}
          onClick={onSave}
          className={styles.button}
        >
          {isLoading ? <Icon name="loading" size="md" /> : 'Save'}
        </Button>
      </div>
    </Modal>
  );
};
