import { Button, Modal } from '@a_team/ui-components';
import { Breakpoints, Colors, TextColors } from '@ateams/components';
import useLoadingState from '@src/hooks/useLoadingState';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import React, { ReactElement, useState, useRef, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import ToS from './steps/ToS';
import cx from 'classnames';
import PlatformCodeOfConduct from '@src/components/Modal/CodeOfConductModal/PlatformCodeOfConduct';
import CoCChecks, { TOTAL_CHECKBOXES } from './steps/CoCChecks';

interface TermsProps {
  alreadyAcceptedTos: boolean;
  handleTermsAccepted: () => void;
}

enum Step {
  TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
  PLATFORM_CODE_OF_CONDUCT = 'PLATFORM_CODE_OF_CONDUCT',
  PLATFORM_CODE_OF_CONDUCT_CHECKS = 'PLATFORM_CODE_OF_CONDUCT_CHECKS',
}

const useStyles = createUseStyles({
  '@global': {
    '.ReactModal__Overlay': {
      background: 'none !important',
    },
  },
  modal: {
    padding: 0,
    '& > div': {
      padding: 0,
      borderRadius: '8px !important',
      position: 'relative',
    },
  },
  content: {
    padding: '24px 16px 92px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h2': {
      textAlign: 'center',
      fontSize: 20,
      fontWeight: 600,
      marginTop: 0,
      marginBottom: 24,
    },
    '& h4': {
      fontSize: 18,
      fontWeight: 500,
      marginTop: 0,
      marginBottom: 16,
    },
    '& a': {
      color: Colors.secondaryDark,
    },
  },
  footer: {
    display: 'flex',
    padding: 16,
    justifyContent: 'space-between',
    backgroundColor: '#F9FAFC',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    borderRadius: 8,
  },
  button: {
    padding: '8px 16px !important',
    cursor: 'pointer !important',
  },
  secondaryBtn: {
    backgroundColor: `${Colors.backgroundDark} !important`,
    color: `${TextColors.regular} !important`,
  },
  secondaryButtonsWrapper: {
    display: 'flex',
    gap: 8,
  },
  confirmBtn: {
    minWidth: '140px !important',
  },
  progressBar: {
    width: '100%',
    height: '4px',
    backgroundColor: 'transparent',
    position: 'fixed',
    bottom: 68,
  },
  progressFill: {
    height: '100%',
    background:
      'linear-gradient(90deg, #FF0FAD 0%, #DD2DF4 36.45%, #9024FF 77.89%, #F8478C 129.32%, #FF7A00 170.43%)',
    transition: 'width 0.2s ease',
  },
  disabledButton: {
    opacity: '0.5',
    cursor: 'not-allowed !important',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    content: {
      padding: '40px 40px 108px',
    },
    footer: {
      padding: '16px 40px',
    },
  },
});

const Terms = ({
  alreadyAcceptedTos,
  handleTermsAccepted,
}: TermsProps): ReactElement | null => {
  const styles = useStyles();
  const { auth } = useStores();
  const [loading, setLoading] = useLoadingState();
  const [step, setStep] = useState<Step>(
    alreadyAcceptedTos ? Step.PLATFORM_CODE_OF_CONDUCT : Step.TERMS_OF_SERVICE,
  );
  const [scrollProgress, setScrollProgress] = useState(0);
  const [hasReadFully, setHasReadFully] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState<Set<string>>(new Set());
  const contentRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (contentRef.current && step === Step.PLATFORM_CODE_OF_CONDUCT) {
      const element = contentRef.current;
      const totalHeight = element.scrollHeight - element.clientHeight;
      const progress = (element.scrollTop / totalHeight) * 100;
      setScrollProgress(progress);

      if (progress >= 90 && !hasReadFully) {
        setHasReadFully(true);
      }
    }
  };

  const handleCheckboxChange = (id: string, checked: boolean) => {
    setCheckedBoxes((prev) => {
      const newSet = new Set(prev);
      if (checked) {
        newSet.add(id);
      } else {
        newSet.delete(id);
      }
      return newSet;
    });
  };

  useEffect(() => {
    setHasReadFully(false);
    setScrollProgress(0);
    setCheckedBoxes(new Set());
  }, [step]);

  const getProgress = () => {
    if (step === Step.PLATFORM_CODE_OF_CONDUCT) {
      return scrollProgress;
    } else if (step === Step.PLATFORM_CODE_OF_CONDUCT_CHECKS) {
      return (checkedBoxes.size / TOTAL_CHECKBOXES) * 100;
    }
    return 0;
  };

  const isButtonEnabled = () => {
    if (step === Step.TERMS_OF_SERVICE) return true;
    if (step === Step.PLATFORM_CODE_OF_CONDUCT) return hasReadFully;
    if (step === Step.PLATFORM_CODE_OF_CONDUCT_CHECKS)
      return checkedBoxes.size === TOTAL_CHECKBOXES; // Adjust number as needed
    return false;
  };

  const handleClick = async () => {
    if (step === Step.TERMS_OF_SERVICE) {
      setLoading(
        auth
          .acceptToS(auth)
          .finally(() => setStep(Step.PLATFORM_CODE_OF_CONDUCT)),
      );
    } else if (step === Step.PLATFORM_CODE_OF_CONDUCT) {
      setStep(Step.PLATFORM_CODE_OF_CONDUCT_CHECKS);
    } else if (step === Step.PLATFORM_CODE_OF_CONDUCT_CHECKS) {
      setLoading(
        auth.acceptPlatformCodeOfConduct(auth).finally(handleTermsAccepted),
      );
    }
  };

  const handleLogout = async () => {
    setLoading(auth.logout().then(() => (window.location.href = '/sign-in')));
  };

  const shouldShowProgress =
    step === Step.PLATFORM_CODE_OF_CONDUCT ||
    step === Step.PLATFORM_CODE_OF_CONDUCT_CHECKS;
  const isButtonDisabled = !isButtonEnabled();

  return (
    <Modal
      variant="slideUp"
      isOpen={true}
      className={styles.modal}
      shouldHideGradientStroke={true}
      hideCloseButton
      styles={{
        width: '680px !important',
      }}
    >
      <div
        className={styles.content}
        ref={contentRef}
        onScroll={handleScroll}
        style={{ maxHeight: 'calc(100vh - 180px)', overflow: 'auto' }}
      >
        {step === Step.TERMS_OF_SERVICE && <ToS />}
        {step === Step.PLATFORM_CODE_OF_CONDUCT && <PlatformCodeOfConduct />}
        {step === Step.PLATFORM_CODE_OF_CONDUCT_CHECKS && (
          <CoCChecks
            checkedBoxes={checkedBoxes}
            onCheckboxChange={handleCheckboxChange}
          />
        )}
      </div>
      {shouldShowProgress && (
        <div className={styles.progressBar}>
          <div
            className={styles.progressFill}
            style={{ width: `${getProgress()}%` }}
          />
        </div>
      )}
      <div className={styles.footer}>
        <div className={styles.secondaryButtonsWrapper}>
          {step === Step.PLATFORM_CODE_OF_CONDUCT_CHECKS && (
            <Button
              className={cx(styles.button, styles.secondaryBtn)}
              onClick={() => setStep(Step.PLATFORM_CODE_OF_CONDUCT)}
            >
              Back
            </Button>
          )}
          <Button
            className={cx(styles.button, styles.secondaryBtn)}
            onClick={handleLogout}
          >
            Sign out
          </Button>
        </div>

        {(step === Step.TERMS_OF_SERVICE ||
          step === Step.PLATFORM_CODE_OF_CONDUCT_CHECKS) && (
          <Button
            className={cx(
              styles.button,
              styles.confirmBtn,
              isButtonDisabled && styles.disabledButton,
            )}
            onClick={handleClick}
            disabled={isButtonDisabled || !!loading}
            loading={!!loading}
          >
            I agree
          </Button>
        )}
        {step === Step.PLATFORM_CODE_OF_CONDUCT && (
          <Button
            className={cx(
              styles.button,
              isButtonDisabled && styles.disabledButton,
            )}
            onClick={handleClick}
            disabled={isButtonDisabled}
          >
            Next
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default observer(Terms);
